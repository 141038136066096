import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lunge Jumps 6×4/leg (no weight, get as high as possible on each jump)`}</p>
    <p>{`Single Leg Hamstring Curl 6×8/leg`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Brian”`}</strong></p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`5-Rope Climbs to 15′`}</p>
    <p>{`25-Back Squats (185/135)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      